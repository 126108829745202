import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Loadable from "react-loadable"

import SEO from "../components/seo"
import Layout from "../components/layout"

// Async the loading of DelaunayCanvas
const LoadDelaunay = Loadable({
  loader: () => import("../components/animations/delaunay-animation"),
  render(loaded, props) {
    let DelaunayCanvas = loaded.default;
    return <DelaunayCanvas />;
  },
  loading() {
    return `<div></div>`
  }
});

const HomePage = (props) => {

  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        sort: {order: DESC, fields: fileAbsolutePath},
        limit: 6
        ) {
        edges {
          node {
            frontmatter {
              description
              title
              published_date
              cat
              lang
              slug
            }
          }
        }
      }
      allContentJson {
        edges {
          node {
            home {
              headband {
                hello
                desc
              }
              about {
                desc
              }
              latestPosts {
                title
                more
              }
            }
          }
        }
      }
    }
  `);

    const content = data.allContentJson.edges[0].node.home
    const latestPosts = data.allMarkdownRemark.edges

    return (
      <Layout {...props}>

        <SEO title="Hey." />

        <div className="l-grid u-pd-hz-m u-pd-hz-0@main">

          <div className="l-col l-col-one-half@main">

            {/* Headband */}
            <p className="c-h1 u-bold u-font-xxl">{content.headband.hello}</p>

            {content.headband.desc.map((item, index) => (
              <div key={'headband-titel-' + index}>
                { (index === 0) && <h1 className="c-h1 u-bold u-mg-bottom-s" key={"headband-desc-" + index}>{item}</h1> }
                { (index > 0) && <h2 className="c-h1 u-bold u-mg-bottom-s" key={"headband-desc-" + index}>{item}</h2> }
              </div>
            ))}

            <hr className="u-mg-top-xl u-mg-bottom-l" />

            {/* About */}
            {content.about.desc.map((item, index) => (
              <p className="c-p u-mg-bottom-s" key={"about-" + index} dangerouslySetInnerHTML={{ __html: item}}></p>
            ))}

            <hr className="u-mg-top-l" />

          </div>

          <div className="l-col l-col-one-half@main">

            <LoadDelaunay />

          </div>

          <div className="l-col l-col-one-half@main">

            {/* Latest posts */}
            <div className="u-pd-vt-l u-pd-hz-m">
              <h3 className="c-h3 u-border-gradient u-inline-block">{content.latestPosts.title}</h3>
            </div>

              <ul className="c-posts-list u-pd-top-m u-pd-left-0">
              {latestPosts.map(({ node }) => {
                return (
                  <li className="c-posts-list__item u-pd-bottom-m u-mg-bottom-l" key={node.frontmatter.slug}>
                    <a className="c-posts-list__link u-no-border" href={node.frontmatter.slug}>
                      <div className="c-posts-list__date">{node.frontmatter.published_date}</div>
                      <div className="c-posts-list__title" dangerouslySetInnerHTML={{
                          __html: node.frontmatter.lang ?
                          '[' + node.frontmatter.lang + '] ' + node.frontmatter.title
                          : node.frontmatter.title,
                        }}></div>
                      <div className="c-posts-list__tags">{node.frontmatter.cat}</div>
                    </a>
                  </li>
                )
              })}
              </ul>

              <Link to={'/blog'}>{content.latestPosts.more}</Link>

              <hr className="u-mg-top-xl" />

              {/* Social */}
              <ul className="u-pd-top-l">
                <li>
                  <a href="https://twitter.com/thibault_mahe" target="_blank" rel="noopener noreferrer" className="u-external-link">Twitter</a>
                </li>
                <li>
                  <a href="http://www.linkedin.com/pub/thibault-mah%C3%A9/49/78b/870" target="_blank" rel="noopener noreferrer" className="u-external-link">LinkedIn</a>
                </li>
                <li>
                  <a href="mailto:&#116;&#104;&#105;&#098;&#097;&#117;&#108;&#116;&#046;&#109;&#097;&#104;&#101;&#064;&#103;&#109;&#097;&#105;&#108;&#046;&#099;&#111;&#109;" target="_blank" rel="noopener noreferrer" className="u-external-link">Mail</a>
                </li>
              </ul>

          </div>

        </div>

      </Layout>
    )

}

export default HomePage

